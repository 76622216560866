<template>
  <div class="book-info" v-if="book">
    <button @click="$emit('back')">&#8592; Back</button>

    <div class="book-details">
      <!-- Book Cover -->
      <img v-if="book.volumeInfo.imageLinks" :src="book.volumeInfo.imageLinks.thumbnail" alt="Book cover" class="book-cover">
      <img v-else src="../assets/p-small.png" alt="Placeholder cover" class="rec-book-cover cursor-pointer">

      <div class="book-text">
        <!-- Book Title and Author -->
        <h2>{{ book.volumeInfo.title }}</h2>
        <p v-if="book.volumeInfo.authors">Author(s): {{ book.volumeInfo.authors.join(', ') }}</p>
        <!-- Number of Pages -->
        <p v-if="book.volumeInfo.pageCount">Number of Pages: {{ book.volumeInfo.pageCount }}</p>
        <!-- Book Description -->
        <p v-if="book.volumeInfo.description">{{ shortDescription }}</p>
        <a v-if="isDescriptionTooLong && !showFullDescription" @click="toggleFullDescription">...more</a>
      </div>
    </div>
  
       <!-- Toggle Favorite and Add to List Buttons -->
    <div class="book-actions">
      <button @click="emitToggleFavorite" :class="{ 'button-active': isFavorite }">
        <i v-if="isFavorite" class="fas fa-star"></i>
        <i v-else class="far fa-star"></i>
      </button>
      <button @click="addBookToReadList" :class="{ 'button-active': isAddedToReadList }">
       📚
      </button>
      <button @click="fetchRecommendations" :class="{ 'button-active': fetchedRecommendations }">Similar Books</button>
    </div>
  </div>
  <div v-else>
    No book selected.
  </div>

  <!-- Add a recommendations section -->
  <div class="book-recommendations" v-if="fetchedRecommendations">
  <p v-if="recommendations.length === 0">No similar books found.</p>
    <div class="recommendations-list" v-if="recommendations.length">
      <div v-for="recBook in recommendations" :key="recBook.id" class="rec-book-item" @click="selectRecommendation(recBook)">
        <img v-if="recBook.volumeInfo.imageLinks && recBook.volumeInfo.imageLinks.smallThumbnail" 
             :src="recBook.volumeInfo.imageLinks.smallThumbnail" 
             alt="Book cover" 
             class="rec-book-cover cursor-pointer">
        <img v-else src="../assets/p-small.png" alt="Placeholder cover" class="rec-book-cover cursor-pointer">
        
        <p>{{ recBook.volumeInfo.title }}</p>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { db, auth } from '@/firebase';
  import { doc, runTransaction } from 'firebase/firestore';
  
  export default {
    name: 'BookInfo',
    emits: [
      'back', 
      'toggle-favorite', 
      'toggle-add-to-list', 
      'book-selected', 
      'request-add-book-to-list' // Ensure this event is declared
    ],
    props: {
      book: {
        type: Object,
        required: true,
      },
    },
    data() {
    return {
      isFavorite: false,
      showFullDescription: false,
      selectedBook: null,
      localBook: null,
      recommendations: [],
      fetchedRecommendations: false,
      selectedList: 'toReadList',
      isAddedToReadList: false,
    };
  },
  computed: {
    shortDescription() {
      if (this.book && this.book.volumeInfo.description) {
        return this.showFullDescription ? this.book.volumeInfo.description : this.book.volumeInfo.description.split('. ').slice(0, 2).join('. ') + '.';
      }
      return '';
    },
    isDescriptionTooLong() {
      return this.book && this.book.volumeInfo.description && this.book.volumeInfo.description.split('. ').length > 2;
    },
  },
  methods: {
    emitToggleFavorite() {
      this.isFavorite = !this.isFavorite;
      this.$emit('toggle-favorite', this.book);
    },
    emitToggleAddToList() {
      this.showAddToListDropdown = !this.showAddToListDropdown;
    },
    
    async addBookToReadList() {
      if (!auth.currentUser) {
        console.error('No user logged in');
        return;
      }
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      try {
        await runTransaction(db, async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (!userDoc.exists()) {
            throw new Error("Document does not exist!");
          }
          const userData = userDoc.data();
          const toReadList = userData.toReadList || [];
          if (!toReadList.some(b => b.id === this.book.id)) {
            toReadList.push(this.book);
            transaction.update(userDocRef, { toReadList });
          }
          this.isAddedToReadList = true;
        });
        console.log('Book added to To-Read List');
      } catch (error) {
        console.error('Error adding book to To-Read List:', error);
      }
    },
  
    toggleFullDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    handleBookSelected(book) {
      this.localBook = book;
      const note = this.$store.state.notes.find(n => n.bookId === book?.id);
      this.notes = note ? note.content : '';
    },
// RECOMMENDATIONS 
async fetchRecommendations() {
  const apiKey = process.env.VUE_APP_GOOGLE_BOOKS_API_KEY || 'AIzaSyDZDK8N-Yy66KL-bbkohePKgKBbs2WqwYg';

  // Get the first author and the first genre
const author = this.book.volumeInfo.authors?.[0];
const genre = this.book.volumeInfo.categories?.[0];
const lgbtKeywords = ["LGBT", "lesbian", "gay", "bisexual", "transgender"];
const keyword = lgbtKeywords.find(keyword => genre?.includes(keyword));

// Form the queries
const authorQuery = author ? `inauthor:"${author}"` : "";
const genreQuery = genre ? `subject:"${genre}"` : "";
const keywordQuery = keyword ? `subject:"${keyword}"` : "";

  // Combine the queries with a logical OR operator to broaden the search
  const combinedQuery = [authorQuery, genreQuery, keywordQuery].filter(Boolean).join('+');
  const apiUrl = `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(combinedQuery)}&key=${apiKey}`;

  try {
    const response = await axios.get(apiUrl);
    const filteredRecommendations = response.data.items?.filter(recBook => recBook.id !== this.book.id) || []; // Filter out the main book
    this.recommendations = filteredRecommendations;
    this.fetchedRecommendations = true;
    console.log("Fetched Recommendations:", this.recommendations); // Log the fetched recommendations
    if (this.recommendations.length === 0) {
      console.log("No similar books found.");
    }
  } catch (error) {
    console.error("Error fetching recommendations:", error); // Log any errors
  }
},
selectRecommendation(recommendedBook) {
  this.$emit('book-selected', recommendedBook);
},
},
watch: {
    book(newBook, oldBook) {
      console.log('Book prop changed. New book:', newBook);
      if (newBook && newBook !== oldBook) {
        this.fetchRecommendations();
      }
    },
  },
  mounted() {
    console.log('BookInfo mounted with book:', this.book);
    
  this.fetchRecommendations();
  },

  };
 

</script>
  
  <style scoped>
  .book-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Center the text */
}

.book-details {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align horizontally */
  margin-bottom: 20px;
}

.book-cover {
  width: 180px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px; /* Space between cover and text */
}
  
  
.book-text {
  width: 80%; /* Adjust width as needed */
  max-width: 600px; /* Maximum width */
}

.book-actions {
  display: flex;
  justify-content: center; /* Center buttons */
  margin-top: 20px; /* Space above the buttons */
}

  @keyframes rainbow-hover-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
  
  button {
    padding: 10px 15px;
    margin: 5px;
    background-color: #333;
    color: #fff;
    border: 2px solid #555;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .button-active {
    background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
    color: black;
  }
  
  button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  background-size: 300% 300%; /* Large size to allow smooth gradient transition */
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
}

.book-recommendations {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align horizontally */
  justify-content: center; /* Center align vertically */
  margin-top: 20px;
  text-align: center; /* Center align text */
}


.recommendations-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.rec-book-item {
  width: 100px;
}

.rec-book-cover {
  width: 100px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px; 
}

h3, p {
  margin-bottom: 10px;
  justify-content: center;
}

  </style>
  