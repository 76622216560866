<template>
  <div class="hello">
    <div class="header">
      <SearchBar v-if="!selectedBook" :searchQuery="searchQuery" :initialResults="searchResults" @search-results="handleSearchResults" @book-selected="handleBookSelected" />
    </div>

    <BookInfo
      v-if="selectedBook"
      :book="selectedBook"
      @add-book-to-list="addBookToList"
      @back="handleBack"
      @toggle-favorite="addToFavorites"
      @toggle-add-to-list="addToToReadList"
      @book-selected="handleBookSelected" />

      <Lists v-if="currentView === 'lists'" />

    <!-- Latest LGBTQ Releases -->
    <div v-if="latestLgbtqBooks.length" class="latest-releases">
      <h2>Random LGBTQ Books</h2>
      <div class="latest-books">
        <div v-for="book in latestLgbtqBooks" :key="book.id" class="book-item" @click="selectLatestBook(book)">

        <img v-if="book.volumeInfo.imageLinks" :src="book.volumeInfo.imageLinks.thumbnail" alt="Book cover" class="book-cover" />
      <img v-else src="../assets/p-small.png" alt="placeholder cover" class="book-cover" />
      <p>{{ shortenTitle(book.volumeInfo.title) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from './SearchBar.vue';
import BookInfo from './BookInfo.vue';
import Lists from './Lists.vue';
import axios from 'axios';
import { db, auth } from '@/firebase';
import { doc, runTransaction} from 'firebase/firestore';



export default {
 

  name: 'HelloWorld',
  components: {
    SearchBar,
    BookInfo,
    Lists,
  },
  
  data() {
    return {
      searchQuery: '',
            searchResults: [],
            selectedBook: null,
            favorites: [],
            toReadList: [],
            latestLgbtqBooks: [],
            lgbtKeywords: ["LGBT", "lesbian", "gay", "bisexual", "transgender", "queer"],
            genreKeywords: ["romance", "fiction", "drama", "comedy", "mystery", "fantasy", "sci-fi", "horror", "thriller", "nonfiction", "biography", "memoir", "history", "poetry", "graphic novel", "young adult", "short story", "essay", "self-help", "health", "cookbook", "travel", "guide", "science", "art", "music", "sports", "crafts", "hobbies", "religion", "spirituality", "philosophy", "politics", "social science", "true crime", "business", "finance", "economics", "parenting", "family", "education", "humor", "comics", "games", "puzzles", "activity book", "journal", "diary", "calendar", "planner", "almanac", "trivia", "reference", "test prep", "foreign language", "textbook", "how-to", "manual", "handbook", "guidebook", "yearbook", "directory", "index", "encyclopedia", "dictionary", "thesaurus", "map", "atlas", "gazetteer", "periodical", "magazine", "newspaper", "journal", "newsletter", "blog", "website", "podcast", "video", "film", "tv", "radio", "broadcast", "audiobook", "ebook", "comic", "graphic novel", "audiobook", "play", "musical", "opera", "dance", "art" ],
            currentView: 'defaultView',
            excludedKeywords: ["children", "kids", "juvenile"],
          };
        },
        created() {
          this.fetchLatestLgbtqBooks();
          
        },
        methods: {
          
          handleSearchResults(results, query) {
            this.searchResults = results;
            this.searchQuery = query;
          },

          shortenTitle(title) {
            const wordLimit = 8;
            const words = title.split(' ');
            if (words.length > wordLimit) {
              return words.slice(0, wordLimit).join(' ') + '...';
            }
            return title;
          },
          handleBookSelected(newBook) {
            this.selectedBook = newBook;
            this.$emit('book-selected', newBook);
          },
          selectLatestBook(book) {
            this.selectedBook = book;
          },
          
          handleBack() {
            this.selectedBook = null; // Just hide the BookInfo component, don't reset searchResults
          },
          addToFavorites(book) {
        if (auth.currentUser && !this.favorites.some(b => b.id === book.id)) {
          this.favorites.push(book);
          const userDocRef = doc(db, `users/${auth.currentUser.uid}`);

          runTransaction(db, async (transaction) => {
            const userDoc = await transaction.get(userDocRef);
            if (!userDoc.exists) {
              throw new Error("Document does not exist!");
            }

            const currentFavorites = userDoc.data().favorites || [];
            const newFavorites = [...currentFavorites, book];
            transaction.update(userDocRef, { favorites: newFavorites });
          }).then(() => {
            console.log("Transaction successfully committed!");
          }).catch((error) => {
            console.error("Transaction failed: ", error);
          });
        } else {
          console.error('User is not logged in.');
        }
      },
      async addToToReadList(book) {
    if (!auth.currentUser) {
      console.error('User is not logged in.');
      return;
    }

    const userDocRef = doc(db, `users/${auth.currentUser.uid}`);

    runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists) {
        throw new Error("Document does not exist!");
      }

      const currentToReadList = userDoc.data().toReadList || [];
      const newToReadList = [...currentToReadList, book];
      transaction.update(userDocRef, { toReadList: newToReadList });
    }).then(() => {
      console.log("Transaction successfully committed for adding to To-Read List!");
    }).catch((error) => {
      console.error("Transaction failed for adding to To-Read List: ", error);
    });
  },

          getRandomElement(arr) {
            return arr[Math.floor(Math.random() * arr.length)];
          },
          async fetchLatestLgbtqBooks() {
            const randomLgbtKeyword = this.getRandomElement(this.lgbtKeywords);
            const randomGenreKeyword = this.getRandomElement(this.genreKeywords);
            const excludedQuery = this.excludedKeywords.map(kw => `-${kw}`).join('+');

    const query = `${randomLgbtKeyword}+AND+${randomGenreKeyword}+AND+${excludedQuery}&maxResults=20`;

            try {
              const response = await axios.get(`https://www.googleapis.com/books/v1/volumes?q=${query}`);
              this.latestLgbtqBooks = response.data.items || [];
            } catch (error) {
              console.error('There was an error!', error);
              this.latestLgbtqBooks = [];
            }
          },
        }};
      
      </script>


      
      <style scoped>
      @keyframes rainbow-hover-animation {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
    }

      h1 {
        margin-bottom: 20px;
      }
       
      .header {
          display: flex;
    justify-content: center; /* Space out navigation and search bar */
  }

  .nav-button {
 
    padding: 10px 15px;
    margin: 5px;
    margin-top: 35px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border-radius: 4px; /* Rounded corners on the right side */
    cursor: pointer;
    
  }

  .nav-button:hover {
    background-color: #0056b3; /* Darken color on hover */
  } 

  .latest-releases {
  margin-top: 20px;
  text-align: center;
}

.latest-books {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.book-item {
  width: 100px;
}

.book-cover {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.book-cover:hover {
  width: 100%;
  height: auto;
  border: 1px solid;
  border-radius: 5px;
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
  transition: border-color 0.3s; 
  cursor: pointer;
}

</style>
