<template>
    <div class="search-container">
      <div class="search-bar">
        <input type="text" v-model="localSearchQuery" placeholder="Search books...">
      </div>
      <div class="search-results" v-if="books.length || searched">
        <div v-for="book in books" :key="book.id" class="book-item" @click="selectBook(book)">
          <img v-if="book.volumeInfo.imageLinks" :src="book.volumeInfo.imageLinks.thumbnail" alt="Book cover" class="book-cover">
          <img v-else src="../assets/p-cover.png" alt="Placeholder cover" class="book-cover">          <div class="book-info">
            <span>{{ book.volumeInfo.title }}</span>
            <span v-if="book.volumeInfo.authors"> by {{ book.volumeInfo.authors.join(', ') }}</span>
          </div>

        </div>
        <p v-if="searched && !books.length">No results found.</p>
        <p v-else-if="searched">Loading...</p>
      </div>
      <button v-if="books.length" @click="loadMoreResults">More</button>

    </div>
  </template>
  
  
<script>
import axios from 'axios';

export default {
  name: 'SearchBar',
  props: {
    searchQuery: String,
    initialResults: Array
  },
  data() {
    return {
        localSearchQuery: this.searchQuery,
      books: this.initialResults || [],
      searched: false,
      startIndex: 0, // Starting index for the results
    maxResults: 20,
    };
  },
  methods: {
    async searchBooks() {
    const lgbtKeywords = ["LGBT", "lesbian", "gay", "bisexual", "transgender"];
    const combinedQuery = `${this.localSearchQuery} ${lgbtKeywords.join(" OR ")}`;
  
   
    const apiKey = process.env.VUE_APP_GOOGLE_BOOKS_API_KEY || 'AIzaSyDZDK8N-Yy66KL-bbkohePKgKBbs2WqwYg';
    const apiUrl = `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(combinedQuery)}&startIndex=${this.startIndex}&maxResults=${this.maxResults}&key=${apiKey}`;


    try {
      const response = await axios.get(apiUrl);
      this.books = response.data.items || [];
      this.$emit('search-results', this.books, this.localSearchQuery);
    } catch (error) {
      console.error('There was an error!', error);
      this.books = [];
    }
  },

    selectBook(book) {
      this.$emit('book-selected', book); // Emit an event with the selected book
    },
    loadMoreResults() {
      this.startIndex += this.maxResults;
    this.searchBooks();
    },
  },
  watch: {
  localSearchQuery() {
    // Reset startIndex and books array when search query changes
    this.startIndex = 0;
    this.books = [];
    this.searchBooks();
  },
},
}
</script>

  
  <style scoped>
.search-container {
  display: flex;
  flex-direction: column; /* Stack search bar and results vertically */
  align-items: center;
  width: 100%;
}

.search-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-bar input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 2px solid transparent; /* Set border to transparent initially */
  border-radius: 4px;
  outline: none;
  background-clip: padding-box; /* Prevents background color from bleeding into the border */
  transition: border-color 0.3s; /* Smooth transition for the border color */
}
  
  .search-bar input[type="text"]:focus {
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }

  .search-bar input[type="text"]:hover {
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
}
  
.search-results {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
  gap: 10px; /* Reduced gap between grid items */
  justify-content: center; /* Centers the grid items */
  width: auto; /* Allows the grid to fit its content width */
  padding: 0; /* Removed padding on the sides */
  margin: 0 auto; /* Centers the grid container */
}


.book-item {
  display: flex;
  align-items: center; /* Align the cover and info vertically in the center */
  margin-bottom: 20px; /* Space between book items */
}

.book-cover {
  width: 100px; /* Adjust the size to fit within the grid */
  height: auto;
  margin-right: 10px; 
  margin-left: 60px; 
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
}

.book-cover:hover {
  width: 100px; /* Adjust the size to fit within the grid */
  height: auto;
  margin-right: 10px; 
  margin-left: 60px; 
  border-radius: 2px;
  border: 1px solid transparent; 
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
}

.book-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align the text to the start of the book info column */
  justify-content: center; /* Center the content vertically */
}

@keyframes rainbow-hover-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #333;
  color: #fff;
  border: 2px solid #555;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  background-size: 300% 300%; /* Large size to allow smooth gradient transition */
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
}

  </style>
  