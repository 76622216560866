import { createStore } from 'vuex';

export default createStore({
  state: {
    currentPage: '',
    previousPage: '',
    notes: [],
  },
  mutations: {
    saveNotes(state, note) {
      // Check if the note for this book already exists
      const existingNoteIndex = state.notes.findIndex(n => n.bookId === note.bookId);
      if (existingNoteIndex !== -1) {
        // Update the existing note
        state.notes[existingNoteIndex].content = note.content;
      } else {
        // Add new note
        state.notes.push(note);
      }
    },
  },
  actions: {
    saveNotes({ commit }, note) {
      commit('saveNotes', note);
    },
  },
});
