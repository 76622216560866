<template>
  <div class="profile-container">
    <div class="tabs">
      <button class="tab" @click="activeTab = 'profile'">Profile</button>
      <button class="tab" @click="activeTab = 'account'">Account</button>
    </div>
<!-- PROFILE TAB CONTENT -->
    <div v-if="activeTab === 'profile'" class="profile">
      <h2>User Profile</h2>
      <div class="form-group">
  <label for="avatar" class="avatar-upload">
    <img v-if="avatarUrl" :src="avatarUrl" alt="Avatar" class="avatar-image"/>
    <i class="upload-icon" v-else>📷</i>
    <input type="file" id="avatar" @change="handleAvatarChange" hidden />
  </label>
</div>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" id="username" v-model="username" placeholder="Enter username" autocomplete="off"/>
      </div>
      <div class="form-group">
        <label for="bio">Bio</label>
        <textarea id="bio" v-model="bio" placeholder="Tell us about yourself"></textarea>
      </div>
      <div class="form-group">
        
      </div>
      <button @click="saveProfile" class="update-profile">Update Profile</button>
    </div>
    <!-- ...other template content -->
    <div v-if="activeTab === 'account'" class="account">
    <h2>Account</h2>
    <div class="message-container" v-if="message" :class="{'success-message': success, 'error-message': !success}">
      {{ message }}
    </div>
    <div v-if="!isLoggedIn">
      <div class="form-group" v-if="!isLoggedIn">
        <input type="email" v-model="email" placeholder="Email" class="email-input"/>
      </div>
      <div class="form-group" v-if="!isLoggedIn">
        <input type="password" v-model="password" placeholder="Password" class="pw-input"/>
      </div>
      
      <div class="form-group" v-if="!isLoggedIn">
        <button @click="login" class="login-button">Login</button>
      </div>
      <div class="form-group" v-if="!isLoggedIn">
        <button @click="signup" class="create-account-button">Create Account</button>
      </div>
    </div>
    <div v-if="isLoggedIn" class="form-group">
  <p>{{ userEmail }} is logged in.</p> <!-- Add this line -->
  <button @click="logout" class="login-button">Logout</button>
</div>
  </div>
<!-- ...other template content -->
  </div>
</template>

<script>
import { auth, db, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from '@/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '@/firebase'; 

export default {
  name: 'UserProfile',
  data() {
    return {
      activeTab: 'profile',
      email: '',
      password: '',   
      message: '',
      success: false,
      isLoggedIn: false,
      username: '',
      bio: '',
      avatarUrl: '',
      userEmail: '',
    };
  },
  watch: {
    '$isLoggedIn'(newVal) {
      this.isLoggedIn = newVal;
    }
  },
  created() {
    this.isLoggedIn = this.$isLoggedIn;
    if (this.isLoggedIn) {
      this.fetchProfile();
    }
  },
  methods: {
    async saveProfile() {
      if (!auth.currentUser) {
        console.error('No user logged in');
        return;
      }

      try {
        const userProfile = {
          username: this.username,
          bio: this.bio,
          avatarUrl: this.avatarUrl,
        };

        const userDoc = doc(db, 'users', auth.currentUser.uid);
        await setDoc(userDoc, userProfile);

        this.message = 'Profile updated successfully!';
        this.success = true;
      } catch (error) {
        this.message = `Error updating profile: ${error.message}`;
        this.success = false;
      }
    },

    async fetchProfile() {
      if (!auth.currentUser) {
        console.error('No user logged in');
        return;
      }try {
    const userDoc = doc(db, 'users', auth.currentUser.uid);
    const docSnap = await getDoc(userDoc);

    if (docSnap.exists()) {
      const userProfile = docSnap.data();
      this.username = userProfile.username;
      this.bio = userProfile.bio;
      this.avatarUrl = userProfile.avatarUrl;
    } else {
      console.error('No profile found for user:', auth.currentUser.uid);
    }
  } catch (error) {
    console.error('Error fetching profile:', error);
  }
    },
    async signup() {
  try {
    await createUserWithEmailAndPassword(auth, this.email, this.password);
    await this.saveProfile();
    this.success = true;
    this.message = 'Account created successfully!';
    this.isLoggedIn = true;
    this.clearMessage();
  } catch (error) {
    this.message = error.message;
    this.success = false;
    this.clearMessage();
    console.error('There was an error during the sign-up process', error);
  }
},
    clearMessage() {
      setTimeout(() => {
        this.message = '';
      }, 5000);
    },
    
    async login() {
  try {
    await signInWithEmailAndPassword(auth, this.email, this.password);
    await this.fetchProfile();
    this.userEmail = auth.currentUser.email;
    this.success = true;
    this.message = 'Logged in successfully!';
    this.isLoggedIn = true;
    this.clearMessage();
  } catch (error) {
    this.message = error.message;
    this.success = false;
    this.clearMessage();
    console.error('There was an error during the login process', error);
  }
},
    async logout() {
      try {
        // Use the imported signOut function
        await signOut(auth);
        this.isLoggedIn = false;
        this.userEmail = '';
        // Handle the sign-out process
        this.success = true;
        this.message = 'Logged out successfully!';
        this.clearMessage();
      } catch (error) {
        // Update UI with error message
        this.message = error.message;
        this.success = false;
        this.clearMessage();
        console.error('There was an error during the logout process', error);
        // Handle errors
      }
    },
    async handleAvatarChange(event) {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const avatarRef = storageRef(storage, `avatars/${auth.currentUser.uid}/${file.name}`);
      const snapshot = await uploadBytes(avatarRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      this.avatarUrl = downloadURL;
      await this.saveProfile(); // Save the URL to Firestore
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  },
    // ...other methods
  },
};
</script>

<style scoped>


@keyframes rainbow-hover-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background: none;
  font-size: 18px;
  margin: 0 5px;
  Color: #333;
  color: white;
  border-radius: 5px;
}

.tab:focus {
  border: 1px;
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
  color: black;
}

.tab:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
  color: black;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.avatar-upload {
  position: relative; /* Needed for absolute positioning of pseudo-element */
  display: inline-block;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  border-radius: 5px; /* Makes it circular */
  background-color: #f0f0f001; /* Background color */
  color: #333; /* Text/icon color */
  text-align: center;
  line-height: 100px; /* Vertically center the icon/text */
  cursor: pointer;
  border: 2px solid transparent; /* Transparent border */
  overflow: hidden; /* Ensures no overflow */
  transition: background-color 0.3s; /* Transition effect */
}

.avatar-upload::after {
  content: '';
  position: absolute;
  top: -2px; /* Adjust based on border size */
  left: -2px; /* Adjust based on border size */
  width: calc(100% + 4px); /* Adjust based on border size */
  height: calc(100% + 4px); /* Adjust based on border size */
  border-radius: 50%; /* Circular shape */
  border: 2px solid transparent;
  transition: border-image 0.3s; /* Transition for the border image */
  pointer-events: none; /* Ensures clicks pass through to the button */
}

.avatar-upload:hover{
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
  border-image-slice: 1;
}


.upload-icon {
  font-size: 24px; /* Adjust icon size as needed */
}

.avatar-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}



.form-group input[type="text"],
.form-group textarea {
  width: 300px; /* You can adjust the width as necessary */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input[type="text"]:hover {
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
}

.form-group textarea {
  height: 100px;
  width: 100%;
}

.form-group textarea:hover {
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
}

.form-group button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background: none;
  font-size: 18px;
  margin: 0 5px;
  Color: #333;
  color: white;
  border-radius: 5px;
}

.update-profile {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background: none;
  font-size: 18px;
  margin: 0 5px;
  Color: #333;
  color: white;
  border-radius: 5px;
}

.update-profile:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
  color: black;
}

.email-input,
.pw-input {
  width: 300px; /* Adjusted width to match other inputs */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #222; /* Dark input background */
  color: white; /* Text color */
}

.email-input:hover,
.pw-input:hover {
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
}

.login-button {
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background: none;
  font-size: 18px;
  justify-content: center;
  color: white;
  border-radius: 5px;
}

.login-button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
  color: black;
}

.create-account-button {
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  background: none;
  font-size: 18px;
  justify-content: center;
  color: white;
}

.create-account-button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
  color: black;
}

.message-container {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  width: 100%; /* Take full width to center the text */
}

.success-message {
  color: #4BB543; /* Success color, you can choose something that fits the theme */
  background-color: #0f0f0f; /* Dark background for the message */
  border: 1px solid #4BB543;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.error-message {
  color: #ff0000; /* Error color, you can choose something that fits the theme */
  background-color: #0f0f0f; /* Dark background for the message */
  border: 1px solid #ff0000;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
