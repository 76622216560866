import { createApp } from 'vue'
import App from './App.vue'
import './global.css'
import router from './router';
import store from './store';
import { auth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';

onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in.
      app.config.globalProperties.$isLoggedIn = true;
      // You could also store the user's information in globalProperties
      app.config.globalProperties.$user = user;
    } else {
      // User is signed out.
      app.config.globalProperties.$isLoggedIn = false;
    }
  });

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');

