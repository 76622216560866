import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue';
import ListsVue from '../components/Lists.vue';
import ProfileVue from '@/components/Profile.vue';
import SearchBarVue from '@/components/SearchBar.vue';
import BookInfoVue from '@/components/BookInfo.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HelloWorld
  },
  {
    path: '/search-bar',
    name: 'Search-Bar',
    component: SearchBarVue,
  },
  {
    path: `/book-info/:bookId`,
    name: 'Book-Info',
    component: BookInfoVue,
    props: true
  },
  {
    path: '/lists',
    name: 'Lists',
    component: ListsVue,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  // Store the current route name before navigation
  localStorage.setItem('currentRoute', to.name);

  // Store the previous route name for 'go back' functionality
  if (from.name) {
    localStorage.setItem('previousRoute', from.name);
  }

  next();
});

export default router;
