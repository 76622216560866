<template>
  <div class="lists-page">
    <button class="sidebar-toggle" @click="toggleSidebar">☰</button>
    <!-- Sidebar for the bookshelves -->
    <aside class="sidebar" :class="{ 'active': isSidebarActive }">
      <h2 class="list-header">Lists</h2>
      <nav class="bookshelves">
        <ul>
          <li><a href="#" @click="showFavorites" :class="{ active: activeTab === 'favorites' }">
            <i class="fa-solid fa-star"></i> Favorites
            </a></li>
    <li><a href="#" @click="showToReadList" :class="{ active: activeTab === 'toReadList' }"><i class="fa-sharp fa-solid fa-list-ul"></i> To-Read</a></li>
    <!-- Loop through custom lists and display them -->
    <li v-for="list in customLists" :key="list.name">
      <a href="#" @click="showCustomList(list)">{{ list.name }}</a>
    </li>
          <li>
  <a href="#" v-if="!showNewListForm" @click="showCreateListForm"><i class="fa-regular fa-square-plus"></i> Create New</a>
  <div v-if="showNewListForm" class="new-list-form">
    <input type="text" v-model="newListName" placeholder="List Name" class="new-list-input">
    <button @click="addNewList">Create</button>
  </div>
</li>
        </ul>
      </nav>
    </aside>

    <!-- Main content area -->
    <main class="main-content">
      <section v-if="activeTab === 'favorites' && favorites.length" class="favorites-section">
      <h3>Favorites</h3>
      <ul class="favorites-list">
        <li v-for="book in favorites" :key="book.id" class="favorite-item">
          <!-- Book Cover with Tooltip -->
          <div class="book-cover-wrapper" @mouseover="showTooltip(book)" @mouseleave="hideTooltip">
            <img v-if="book.volumeInfo.imageLinks" :src="book.volumeInfo.imageLinks.thumbnail" alt="Book cover" class="book-cover"/>
            <img v-else src="../assets/p-small.png" alt="placeholder cover" class="book-cover"/>
            <!-- Tooltip Element -->
            <div v-if="tooltipVisible" class="tooltip"> {{ truncateDescription(book.volumeInfo.description) }}</div>
          </div>
          <!-- Book Info -->
          <div class="book-info">
            
            <!-- More book information can be added here -->
            <!-- Dropdown Select -->
          <div class="select-wrapper">
            <select v-model="book.selectedList" @change="moveBook(book)">
              <option value="" disabled selected>Select List</option>
              <option value="favorites">✨ Favorites</option>
              <option value="toReadList">📚 To-Read List</option>
              <option v-for="list in customLists" :value="list.name" :key="list.name">{{ list.name }}</option>
            </select>
          </div>
          </div>
          
        </li>
      </ul>
    </section>

    <section v-if="activeTab === 'toReadList' && toReadList.length" class="to-read-section">
  <h3>To-Read List</h3>
  <ul class="to-read-list">
    <li v-for="book in toReadList" :key="book.id" class="to-read-item">
      <!-- Book cover wrapper with tooltip -->
      <div class="book-cover-wrapper">
        <img v-if="book.volumeInfo.imageLinks" 
             :src="book.volumeInfo.imageLinks.thumbnail" 
             alt="Book cover" 
             class="book-cover"
             @click="selectBook(book)" />
        <img v-else 
             src="../assets/p-small.png" 
             alt="Placeholder cover" 
             class="book-cover" />

        <!-- Tooltip for book description -->
        <div class="tooltip">{{ truncateDescription(book.volumeInfo.description) || 'No description available' }}</div>
      </div>
      <div class="book-info">
        <div class="select-wrapper">
        <select v-model="book.selectedList" @change="moveBook(book)">
          <option value="" disabled selected>Select List</option>
          <option value="favorites">✨ Favorites</option>
          <option value="toReadList">📚 To-Read List</option>
          <option v-for="list in customLists" :value="list.name" :key="list.name">{{ list.name }}</option>
        </select>
      </div>
      </div>
    </li>
  </ul>
</section>

      <!-- Section for displaying books from the active custom list -->
<section v-if="isCustomListActive && activeListBooks.length" class="custom-list-section">
  <h3>{{ activeTab }}</h3>
  <ul class="custom-list">
    <li v-for="book in activeListBooks" :key="book.id" class="custom-list-item">
      <!-- Book cover wrapper with tooltip -->
      <div class="book-cover-wrapper">
        <img v-if="book.volumeInfo.imageLinks"
             :src="book.volumeInfo.imageLinks.thumbnail"
             alt="Book cover"
             class="book-cover"
             @click="selectBook(book)" />
        <img v-else
             src="../assets/p-small.png"
             alt="Placeholder cover"
             class="book-cover" />

        <!-- Tooltip for book description -->
        <div class="tooltip">{{ truncateDescription(book.volumeInfo.description) || 'No description available' }}</div>
      </div>

      <div class="book-info">
        <div class="select-wrapper">
        <select v-model="book.selectedList" @change="moveBook(book)">
          <option value="" disabled selected>Select List</option>
          <option value="favorites">✨ Favorites</option>
          <option value="toReadList">📚 To-Read List</option>
          <option v-for="list in customLists" :value="list.name" :key="list.name">{{ list.name }}</option>
        </select>
      </div>
      </div>
    </li>
  </ul>
</section>
    </main>
  </div>
</template>


<script>
import { auth, db } from '@/firebase';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

export default {
  name: 'BookLists',
  props: {
    
  },
  data() {
    return {
      newListName: '', // Holds the name of the new list
      favorites: [],
      toReadList: [],
      activeTab: 'favorites',
      showNewListForm: false,
      customLists: [],
      activeListBooks: [],
      selectedList: '', 
      tooltipVisible: false,
      tooltipContent: '',
      isSidebarActive: false,
    };
  },
  computed: {
    isCustomListActive() {
      // This computed property checks if the active tab is a custom list
      return this.activeTab !== 'favorites' && this.activeTab !== 'toReadList';
    },
  },
  mounted() {
    this.fetchFavorites();
    this.fetchToReadList();
    this.fetchCustomLists();
  },
  methods: {
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
      if (this.isSidebarActive) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    showFavorites() {
    this.activeTab = 'favorites';
  },

  showToReadList() {
    this.activeTab = 'toReadList';
  },
    goBack() {
      this.$router.push({ name: this.$store.state.previousPage });
    },
    showCreateListForm() {
    this.showNewListForm = true;
  },

  async addNewList() {
  if (!this.newListName.trim()) {
    console.error('Please enter a name for the list.');
    return;
  }
  const userDocRef = doc(db, 'users', auth.currentUser.uid);
  try {
    await updateDoc(userDocRef, {
      customLists: arrayUnion({ name: this.newListName, books: [] })
    });
    console.log('New list created:', this.newListName);
    this.newListName = '';
    this.showNewListForm = false;
    await this.fetchCustomLists(); // Fetch updated custom lists
  } catch (error) {
    console.error("Error creating new list:", error);
  }
},
showCustomList(list) {
    this.activeTab = list.name;
    this.fetchBooksForList(list.name);
  },
  
  async fetchCustomLists() {
  if (auth.currentUser) {
    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists() && docSnap.data().customLists) {
        this.customLists = docSnap.data().customLists;
      } else {
        this.customLists = []; // Reset to empty if not found
      }
    } catch (error) {
      console.error("Error fetching custom lists:", error);
      this.customLists = []; // Reset to empty in case of error
    }
  }
},

async fetchBooksForList(listName) {
    if (!auth.currentUser) {
      console.error('No user logged in');
      return;
    }

    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (listName === 'favorites' || listName === 'toReadList') {
          // Predefined lists
          this[listName] = userData[listName] || [];
        } else {
          // Custom lists
          const customList = userData.customLists.find(list => list.name === listName);
          if (customList) {
            this.activeListBooks = customList.books || []; // Assuming activeListBooks is a data property to store books of the active list
          } else {
            this.activeListBooks = [];
          }
        }
      }
    } catch (error) {
      console.error(`Error fetching books for list ${listName}:`, error);
    }
  },

    async toggleFavorite(book) {
      console.log("Attempting to toggle favorite for book:", book);
  if (!auth.currentUser) {
    alert('Please log in to use this feature');
    return;
  }

  const userDocRef = doc(db, 'users', auth.currentUser.uid);
  console.log('Toggling favorite for book:', book);

  if (this.isBookFavorited(book)) {
    console.log('Removing from favorites:', book);
    await updateDoc(userDocRef, {
      favorites: arrayRemove(book)
    });
    this.favorites = this.favorites.filter(b => b.id !== book.id);
  } else {
    console.log('Adding to favorites:', book);
    await updateDoc(userDocRef, {
      favorites: arrayUnion(book)
    });
    this.favorites.push(book);
  }
},

    isBookFavorited(book) {
      return this.favorites.some(b => b.id === book.id);
    },
    async fetchFavorites() {
  if (auth.currentUser) {
    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists() && Array.isArray(docSnap.data().favorites)) {
        this.favorites = docSnap.data().favorites;
      } else {
        this.favorites = []; // Ensure it's reset to an empty array if not found
      }
    } catch (error) {
      console.error("Error fetching favorites:", error);
      this.favorites = []; // Ensure it's reset to an empty array in case of error
    }
  }
},
async fetchToReadList() {
  if (auth.currentUser) {
    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    try {
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        this.toReadList = docSnap.data().toReadList || [];
      } else {
        this.toReadList = [];
      }
    } catch (error) {
      console.error("Error fetching to-read list:", error);
      this.toReadList = [];
    }
  }
},

async moveBook(book) {
    if (!auth.currentUser) {
      console.error('No user logged in');
      return;
    }

    const userDocRef = doc(db, 'users', auth.currentUser.uid);
    const newList = book.selectedList;

    try {
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      // Check if the book is already in the new list
      const isBookInNewList = newList !== 'favorites' && newList !== 'toReadList'
        ? userData.customLists.some(list => list.name === newList && list.books.some(b => b.id === book.id))
        : userData[newList].some(b => b.id === book.id);

      if (!isBookInNewList) {
        // Add the book to the new list if it's not already there
        if (newList !== 'favorites' && newList !== 'toReadList') {
          const updatedCustomLists = userData.customLists.map(list => {
            if (list.name === newList) {
              list.books = [...new Set([...list.books, book])]; // Use a Set to avoid duplicates
            }
            return list;
          });
          await updateDoc(userDocRef, { customLists: updatedCustomLists });
        } else {
          await updateDoc(userDocRef, {
            [newList]: arrayUnion(book)
          });
        }
      }

      // Remove the book from the old list if necessary
      // ... logic to remove book from the old list ...

      console.log('Book moved to', newList);
    } catch (error) {
      console.error('Error moving book:', error);
    }

    // Refresh the lists to reflect changes
    this.fetchFavorites();
    this.fetchToReadList();
    this.fetchCustomLists();
  },
  selectBook(book) {
      this.$emit('book-selected', book);
    },
    showTooltip(book) {
      this.tooltipContent = book.volumeInfo.description || 'No description available.';
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    truncateDescription(description, maxWords = 85) {
      if (!description) return 'No description available.';
      const words = description.split(' ');
      if (words.length > maxWords) {
        return words.slice(0, maxWords).join(' ') + '...';
      }
      return description;
    },
  },
  }

</script>

  
<style scoped>

@keyframes rainbow-hover-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* General styles for lists and items */
.lists-page,
.sidebar,
.main-content,
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.lists-page {
  display: flex;
  background-color: transparent; /* Using transparent shorthand */
}

.sidebar {
  width: 200px;
  padding: 20px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

/* Anchor tag styles */
.bookshelves li a {
  text-decoration: none;
  color: #ccc;
  padding: 10px;
  display: block;
  transition: color 0.3s ease;
}

.bookshelves li a.active,
.bookshelves li a:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  background-size: 300% 300%;
  animation: rainbow-hover-animation 2s infinite;
  color: black;
  border: none; /* Set to none instead of transparent for shorthand */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: transparent; /* Using transparent shorthand */
  color: #ccc;
}

/* Shared list styles */
.favorites-list,
.to-read-list,
.custom-list,
.custom-list-section .custom-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px; /* Shorthand for row and column gaps */
}

/* Shared item styles */
.favorite-item,
.to-read-item,
.custom-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column; /* Changed from row to column for consistency */
}

/* Book cover styles */
.book-cover {
  width: 100px;
  height: auto;
  margin-bottom: 8px;
  border-radius: 5px;
  object-fit: cover;
  border: none; /* Set to none instead of transparent for shorthand */
}

.book-cover:hover {
  border: 2px solid; /* Removed the transparent value for border */
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
}

/* Button styles */
button,
.new-list-form button {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  border: none; /* Removed redundant properties */
  cursor: pointer;
}

button:hover,
.new-list-input:hover,
.new-list-form button:hover {
  background-color: #0056b3; /* Removed animation for cleaner code */
}

/* Input styles */
input[type="text"],
.new-list-input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
}

/* Select wrapper styles */
.select-wrapper {
  position: relative;
  width: 100%; /* Ensuring full width */
  margin-top: 10px; /* Spacing from the title */
}

.select-wrapper select {
  width: auto;
  min-width: 80px;
  padding: 5px 30px 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgba(33, 32, 32, 0.789);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="%23ccc" viewBox="0 0 20 20"><polygon points="6,8 14,8 10,12"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  cursor: pointer;
  color: white;
  line-height: 1.5;
  -webkit-appearance: none; /* Removing default appearance */
  -moz-appearance: none;
  appearance: none;
}

.select-wrapper select:focus {
  outline: none;
  border-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066) 1;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.5);
}

/* Title and tooltip styles */
.book-title {
  max-width: 150px; /* Ensuring titles do not overflow */
  text-overflow: ellipsis; /* Adding ellipsis to overflowing text */
  overflow: hidden; /* Hiding overflow */
  white-space: nowrap; /* Preventing wrap to new line */
}

.book-cover-wrapper {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.tooltip {
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 15px;
  border-radius: 8px;
  width: 320px;
  height: 400px;
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  z-index: 10;
  visibility: hidden;
}

.book-cover-wrapper:hover .tooltip {
  visibility: visible;
}

.sidebar-toggle {
  display: none;
}

/* MOBILE CSS*/
@media (max-width: 768px) {
  /* Hide the sidebar by default on mobile and make it toggleable */
  .sidebar {
    position: absolute; /* Absolute positioning */
    left: -100vw; /* Use 100vw to ensure full viewport width is moved */
    width: 80%; /* Sidebar takes 80% of the screen width */
    height: 100%; /* Full height */
    transition: left 0.3s ease;
    background-color: #121111; /* Background color */
    z-index: 102;
  }

  /* Button to toggle the sidebar, make it smaller and fix its position */
  .sidebar-toggle {
  position: fixed;
  left: 10px; /* Provide some space from the left edge */
  top: 10px; /* Provide some space from the top edge */
  z-index: 105; /* Ensure it's above other content */
  padding: 12px; /* Generous padding to increase size */
  font-size: 24px; /* A larger font size for the icon */
  background-color: #333; /* Background color to match your design */
  color: white;
  border-radius: 50%; /* Circular shape */
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional: Adds a subtle shadow for depth */
  display: flex; /* Ensures the content is centered */
  justify-content: center;
  align-items: center;
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height, should be equal to width for a perfect circle */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

  /* Show sidebar when active */
  .sidebar.active {
    left: 0;
  }

  /* Adjust the content layout on mobile */
  .lists-page {
    flex-direction: column;
  }

  /* Adjust the main content for mobile */
  .main-content {
    padding-left: 0;
    transition: margin-left 0.3s ease;
  }
  .sidebar.active + .main-content {
    z-index: 101;
    padding-left: 80%; /* Same as sidebar width */
  }

  /* Display books two per line on mobile */
  .favorites-list,
  .to-read-list,
  .custom-list {
   
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns grid */
    gap: 10px;
  }

  .favorite-item,
  .to-read-item,
  .custom-list-item {
    width: auto; /* Auto width to fit grid layout */
  }

  /* Adjust the book cover size for mobile */
  .book-cover {
    
    width: 100%; /* Adjust to 100% of the grid column */
    margin-bottom: 5px;
  }

  /* Adjust the book info for mobile */
  .book-info {
    text-align: center; /* Center align the text on mobile */
    padding: 0;
  }

  /* Adjust the select dropdown size for mobile */
  .select-wrapper select {
    width: 100%; /* Full width on mobile */
    padding: 10px;
  }

  /* Adjust the tooltip for mobile */
  .tooltip {
    z-index: 1;
    width: 100%; /* Full width on mobile */
    left: 0; /* Align to the left edge */
    transform: none; /* Remove transform */
    padding: 10px;
    box-sizing: border-box; /* Include padding in width calculation */
  }
}

</style>
