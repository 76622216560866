<template>
  <div id="app">
    <div class="header">
      <img alt="QueerReads logo" src="./assets/qr1.png" class="logo" @click="goHome">
      <div @click="goHome" class="home-link">
        <h1>QueerReads</h1>
      </div>
      <div class="nav-button" @click="goHome"><i class="fa-solid fa-house-chimney"></i></div>
      <div class="nav-button" @click="currentView = 'lists'"><i class="fa-solid fa-book-open"></i></div>
      <div class="nav-button" @click="currentView = 'profile'"><i class="fa-solid fa-address-card"></i></div>
    </div>

    <!-- Conditional rendering based on currentView -->
    <HelloWorld v-if="currentView === 'hello-world'"/>
    <Lists v-else-if="currentView === 'lists'" ref="bookListsComponent" />
    <Profile v-else-if="currentView === 'profile'"/>

    <!-- BookInfo component -->
    <BookInfo
    v-if="selectedBook"
    :book="selectedBook"
    @request-add-book-to-list="addBookToList"
    />

    <!-- Footer component -->
    <AppFooter />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
import Lists from './components/Lists.vue';
import Profile from './components/Profile.vue';
import BookInfo from './components/BookInfo.vue';
import { db } from '@/firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import AppFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    HelloWorld,
    Lists,
    Profile,
    BookInfo,
    AppFooter,
  },
  data() {
    return {
      currentView: 'hello-world',
      favorites: [],
      toReadList: [],
      selectedBook: null,
    };
  },
  methods: {
    
    handleFavoriteToggle(book) {
      // Call a method in lists.vue to update favorites
      this.$refs.bookListsComponent.toggleFavorite(book);
    },
    goHome() {
      // Use the JavaScript window.location object to change the current URL
      window.location.href = '/';
    },
    async addBookToList({ book, listName }) {
      // Placeholder logic for adding a book to a Firestore list
      // Replace with your actual Firestore update logic
      console.log(`Adding ${book.title} to ${listName}`);
      const userDocRef = doc(db, 'users', 'yourUserId'); // Replace 'yourUserId' with actual user ID
      try {
        await updateDoc(userDocRef, {
          [listName]: arrayUnion(book)
        });
        console.log(`Book added to ${listName}`);
        // Refresh custom lists here if necessary
      } catch (error) {
        console.error('Error updating custom list:', error);
      }
    },
  },
  computed: {
    currentViewComponent() {
      switch (this.currentView) {
        case 'lists':
          return Lists;
        case 'profile':
          return Profile;
        default:
          return HelloWorld;
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
}

.logo {
  height: 50px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for the shadow */
}

.logo:hover {
  animation: rainbow-glow-animation 2s infinite alternate;
}

@keyframes rainbow-glow-animation {
  0%, 100% {
    box-shadow:
      0 0 10px #FF0000,
      0 0 20px #FF7F00,
      0 0 30px #FFFF00,
      0 0 40px #00FF00,
      0 0 50px #0000FF,
      0 0 60px #4B0082,
      0 0 70px #9400D3;
  }
  50% {
    box-shadow:
      0 0 15px #FF0000,
      0 0 25px #FF7F00,
      0 0 35px #FFFF00,
      0 0 45px #00FF00,
      0 0 55px #0000FF,
      0 0 65px #4B0082,
      0 0 75px #9400D3;
  }
}

@keyframes rainbow-hover-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.nav-button {
  width: 30px; /* Same as height for a circle */
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #31373d00; /* Default background color */
  color: white;
  border-radius: 50%; /* Circle shape */
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s, background-image 0.3s; /* Smooth transition for background */
}

/* Add this CSS for the hover state */
.nav-button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  background-size: 300% 300%; /* Large size to allow smooth gradient transition */
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
  color: black;
}

.home-link h1 {
  display: inline-block; /* Make the h1 inline for better control */
  cursor: pointer;
  transition: color 0.3s; /* Smooth transition for text color */
}

.home-link h1:hover {
  text-shadow: 2px 2px #ff0000, 4px 4px #ff9900, 6px 6px #ccff00, 8px 8px #33ff00, 10px 10px #00ff66, 12px 12px #00ffff, 14px 14px #0066ff, 16px 16px #7a00ff, 18px 18px #ff00ff, 20px 20px #ff0066;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    justify-content: space-between; /* Align items to each end */
    padding: 0 10px; /* Add padding on the sides */
  }

  .header .logo {
    order: -1; /* Ensure logo is first */
   
  }

  .header .home-link h1 { /* Hide all nav-buttons except the toggle button */
    display: none;
  }

  .header .nav-button:first-child {
    display: block; /* Ensure the toggle button is displayed */
  }
}

</style>
