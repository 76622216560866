<template>
    <div>
    <!-- About Us section that toggles on click -->
    <div v-if="showAbout" class="about-section">
      <h2>About Us</h2>
      <p>QueerReads is dedicated to celebrating and promoting LGBTQ+ literature. Our platform is a vibrant community made to discover, and delve into queer narratives across a spectrum of genres and formats.</p>
    <p><strong>Disclaimer:</strong> We are continually enhancing QueerReads to provide the best experience. As we refine our search algorithms, some titles may not perfectly align with queer genres. We appreciate your patience and understanding. 
        QueerReads is committed to fostering a diverse collection of LGBTQ+ literature. While we strive to cater to all readers, please be advised that some titles may contain mature content and may not be suitable for younger audiences.</p>
    <button @click="showAbout = false" class="close-button">Close</button>
    </div>
     <!-- Contact Us section -->
    <div v-if="showContactInfo" class="contact-info-section">
      <p>Email us at: <a href="mailto:info@queerreads.co">info@queerreads.co</a></p>
      <button @click="showContactInfo = false">Close</button>
    </div>
    <!-- Footer section -->
    <div class="footer" v-else>
      <div class="container">
        <div class="about" @click="showAbout = true">
          About
        </div>
        <p class="copyright">
          © {{ currentYear }} QueerReads
        </p>
        <div class="contact" @click="toggleContactInfo">
          Contact
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showAbout: false,
      showContactForm: false, // New data property for toggling the contact form
      showContactInfo: false,
    };
  },
  methods: {
    toggleAbout() {
    this.showAbout = !this.showAbout;
    if (this.showAbout) {
      this.showContactForm = false;
    }
  },
  toggleContactInfo() {
      this.showContactInfo = !this.showContactInfo;
      if (this.showContactInfo) {
        this.showAbout = false;
      }
    },
  }
}
</script>
  
  <style scoped>
.footer {
  background-color: #1a1a1a; /* Darker shade */
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
}

.container {
    background-color: #1a1a1a; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.about {
  text-align: left; /* Align text to the left */
  flex: 1; /* Take up the space on the left side */
  cursor: pointer;
}

.about:hover {
  animation: rainbow-text-animation 1s infinite;
}

.contact {
  text-align: right; /* Align text to the right */
  flex: 1; /* Take up the space on the right side */
  cursor: pointer;
}

.contact:hover {
  animation: rainbow-text-animation 1s infinite;
}

@keyframes rainbow-text-animation {
  0% { color: red; }
  14% { color: orange; }
  28% { color: yellow; }
  42% { color: green; }
  56% { color: blue; }
  70% { color: indigo; }
  84% { color: violet; }
  100% { color: red; }
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

p:hover {
  animation: rainbow-text-animation 1s infinite;
}

.about-section {
  padding: 20px;
  background-color: #1a1a1a;
  color: white;
  text-align: center;
}

.close-button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #333;
  color: #fff;
  border: 2px solid #555;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.close-button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  background-size: 300% 300%; /* Large size to allow smooth gradient transition */
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
}

.contact-info-section {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  text-align: center;


}

.contact-info-section p {
  margin-bottom: 10px;
}

.contact-info-section a {
  color: #fff;
  animation: rainbow-text-animation 1s infinite;
}

.contact-info-section button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #333;
  color: #fff;
  border: 2px solid #555;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.contact-info-section button:hover {
  background-image: linear-gradient(90deg, #ff0000, #ff9900, #ccff00, #33ff00, #00ff66, #00ffff, #0066ff, #7a00ff, #ff00ff, #ff0066);
  background-size: 300% 300%; /* Large size to allow smooth gradient transition */
  animation: rainbow-hover-animation 2s infinite; /* Apply the animation */
}

@keyframes rainbow-hover-animation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* You may not need this media query if you're stacking items vertically */
@media (max-width: 768px) {
  .about-section, .contact-info-section {
    width: 95%; /* Use more of the screen width on smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
    margin: 10px auto; /* Reduce margin for smaller screens */
  }

  .close-button, .contact-info-section button {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

</style>

  